import React from 'react'
import Layout from '../../layouts'

export default () => (
  <Layout>
    <div>
      <h2>Datenschutzerklärung</h2>
      <p>
        Ein Besuch unserer Website www.fewo-allgaier.de ist grundsätzlich ohne
        jede Angabe persönlicher Daten möglich. Sofern Sie jedoch über unsere
        Internetseite Kontakt mit uns aufnehmen wollen, wird die Angabe Ihrer
        persönlichen Daten für die Bearbeitung der Anfrage verlangt. Da es für
        die Verarbeitung personenbezogener Daten in diesem Fall keine
        gesetzliche Grundlage gibt, holen wir generell die Einwilligung der
        anfragenden Person ein
      </p>
      <p>
        Die Verarbeitung personenbezogener Daten, wie bspw. des Vor- und
        Nachnamens, der Anschrift, E-Mail-Adresse oder Telefonnummer, sowie von
        Texteingaben, Fotografien, Nutzungsdaten, besuchten Webseiten,
        Zugriffszeiten, IP-Adressen und Metadaten, erfolgt stets im Einklang mit
        der Datenschutz-Grundverordnung und in Übereinstimmung mit den für unser
        Haus geltenden landesspezifischen Datenschutzbestimmungen. Mittels
        dieser Datenschutzerklärung möchte unser Haus die Öffentlichkeit über
        Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten
        personenbezogenen Daten informieren. Ferner werden betroffene Personen
        mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte
        aufgeklärt.
        <br />
        Ich, Alois Allgaier, habe als „für die Verarbeitung Verantwortlicher“
        zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen
        möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten
        personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte
        Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, so dass
        ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund
        steht es jeder betroffenen Person frei, personenbezogene Daten auch auf
        alternativen Wegen, beispielsweise telefonisch, an mich zu übermitteln
      </p>
      <h2>1. Begriffsbestimmungen</h2>
      <p>
        Diese Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch
        die EU beim Erlass der DSGVO verwendet wurden. Sie soll für alle einfach
        lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir
        vorab auch folgende, verwendete Begriffe erläutern:
      </p>
      <h3>a) Personenbezogene Daten / persönliche Daten</h3>
      <p>
        Personenbezogene Daten sind alle Informationen und gespeicherten
        Hinweise, aufgrund derer sich eine natürliche Person identifizieren
        lässt.
      </p>
      <h3>b) Verarbeitung</h3>
      <p>
        Verarbeitung ist jeder manuell oder technisch durchgeführte Vorgang,
        beim dem personenbezogene Daten geladen, zugeordnet, gespeichert,
        geändert oder gelöscht werden.
      </p>
      <h3>c) Verantwortlicher oder für die Verarbeitung Verantwortlicher</h3>
      <p>
        Als „Verantwortlicher“ bezeichnen wir Personen, Behörden oder
        Einrichtungen, die über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheiden.
      </p>
      <h2>2. Name und Anschrift des Verantwortlichen im Sinne der DSGVO</h2>
      <p>
        Alois Allgaier<br />
        Ferienwohnung Allgaier<br />
        Wank 3<br />
        87484 Nesselwang<br />
        Tel.: 08361 3998<br />
        E-Mail: <a href="mailto:info@fewo-allgaier.de">info@fewo-allgaier.de</a>
        <br />
        Website: <a href="https://www.fewo-allgaier.de">www.fewo-allgaier.de</a>
      </p>
      <h2>
        3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
        von deren Verwendung
      </h2>
      <h3>a) Beim Besuch der Website</h3>
      <p>
        Beim Aufrufen unserer Website{' '}
        <a href="https://www.fewo-allgaier.de">www.fewo-allgaier.de</a> werden
        durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch
        Informationen an den Server unserer Website gesendet. Diese
        Informationen werden temporär in einem sog. Logfile gespeichert.
        Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur
        automatisierten Löschung gespeichert:
      </p>
      <ul>
        <li>IP-Adresse des anfragenden Rechners,</li>
        <li>Datum und Uhrzeit des Zugriffs,</li>
        <li>Name und URL der abgerufenen Datei,</li>
        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
        <li>
          verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
          der
        </li>
        <li>Name Ihres Access-Providers.</li>
      </ul>
      <p>
        Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
      </p>
      <ul>
        <li>
          Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
        </li>
        <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
        <li>
          Auswertung der Systemsicherheit und -stabilität sowie zu weiteren
          administrativen Zwecken.
        </li>
      </ul>
      <p>
        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
        lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten
        Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen
        Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. <br />
        Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie
        Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den
        Ziff. 4 und 5 dieser Datenschutzerklärung.
      </p>
      <h3>b) Bei Nutzung unseres Kontaktformulars</h3>
      <p>
        Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über
        ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei
        ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir
        wissen, von wem die Anfrage stammt und um diese beantworten zu können.
        Weitere Angaben können freiwillig getätigt werden. <br />
        Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
        nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
        erteilten Einwilligung.<br />Die für die Benutzung des Kontaktformulars
        von uns erhobenen personenbezogenen Daten werden nach Erledigung der von
        Ihnen gestellten Anfrage automatisch gelöscht.
      </p>
      <h2>4. Weitergabe von Daten</h2>
      <p>
        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den
        im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre
        persönlichen Daten nur an Dritte weiter, wenn:
      </p>
      <ul>
        <li>
          Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
          Einwilligung dazu erteilt haben,
        </li>
        <li>
          die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
          überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
          Daten haben,
        </li>
        <li>
          für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
          DSGVO eine gesetzliche Verpflichtung besteht, sowie
        </li>
        <li>
          dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für
          die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
        </li>
      </ul>
      <h2>5. Cookies</h2>
      <p>
        Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
        kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
        Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
        unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden
        an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.<br />
        In dem Cookie werden Informationen abgelegt, die sich jeweils im
        Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
        bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
        Identität erhalten.<br />
        Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres
        Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte
        Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer
        Website bereits besucht haben. Diese werden nach Verlassen unserer Seite
        automatisch gelöscht.<br />
        Darüber hinaus setzen wir ebenfalls zur Optimierung der
        Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
        festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen
        Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird
        automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben
        und Einstellungen sie getätigt haben, um diese nicht noch einmal
        eingeben zu müssen.<br />
        Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website
        statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes
        für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns,
        bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass
        Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils
        definierten Zeit automatisch gelöscht.<br />
        Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur
        Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6
        Abs. 1 S. 1 lit. f DSGVO erforderlich.<br />
        Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
        Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer
        gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer
        Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
        jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website
        nutzen können.<br />
      </p>
      <h2>6. Plugins und Tools</h2>
      <h3>a) Google Maps</h3>
      <p>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
        ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
        USA.
      </p>
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
        Adresse zu speichern. Diese Informationen werden in der Regel an einen
        Server von Google in den USA übertragen und dort gespeichert. Der
        Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
      </p>
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google: <a
          href="https://www.google.de/intl/de/policies/privacy/"
          target="__blank"
          rel="noopener"
        >
          https://www.google.de/intl/de/policies/privacy/
        </a>.
      </p>
      <h3>b) Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung
        von Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter <a
          href="https://developers.google.com/fonts/faq"
          target="__blank"
          rel="noopener"
        >
          https://developers.google.com/fonts/faq
        </a> und in der Datenschutzerklärung von Google: <a
          href="https://www.google.com/policies/privacy/"
          target="__blank"
          rel="noopener"
        >
          https://www.google.com/policies/privacy/
        </a>.
      </p>
      <h2>7. Betroffenenrechte</h2>
      <p>Sie haben das Recht:</p>
      <ul>
        <li>
          gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
          offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
          Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
          die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
          sowie über das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling und ggf. aussagekräftigen Informationen zu
          deren Einzelheiten verlangen;
        </li>
        <li>
          gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu verlangen;
        </li>
        <li>
          gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch
          gegen die Verarbeitung eingelegt haben;
        </li>
        <li>
          gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen;
        </li>
        <li>
          gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortführen dürfen und
        </li>
        <li>
          gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
          Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat.
        </li>
      </ul>
      <h2>8. Widerspruchsrecht</h2>
      <p>
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
        Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
        haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
        Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
        Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
        sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
        haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
        besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem
        Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
        info@fewo-allgaier.de.
      </p>
      <h2>9. Datensicherheit</h2>
      <p>
        Wir verwenden innerhalb des Website-Besuchs das verbreitete
        SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
        höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
        In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung.
        Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir
        stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite
        unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
        an der geschlossenen Darstellung des Schüssel- beziehungsweise
        Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
      </p>
      <p>
        Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer
        Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche
        Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder
        gegen den unbefugten Zugriff Dritter zu schützen. Unsere
        Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung
        fortlaufend verbessert.
      </p>
      <h2>10. Aktualität und Änderung dieser Datenschutzerklärung</h2>
      <p>
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Juli
        2018. Durch die Weiterentwicklung unserer Website und Angebote darüber
        oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
        Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
        Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website{' '}
        <a href="https://www.fewo-allgaier.de">www.fewo-allgaier.de</a> von
        Ihnen abgerufen und ausgedruckt werden.
      </p>
    </div>
  </Layout>
)
